
.day-selector{
    background: #035797;
    width: 100%;
    padding: 10px;
    padding-top: 10px;
    display: flex;
   flex-direction: row;
   align-items: center;
  }

  .day-selector:hover{
    cursor: pointer;
  }

  .day-selector-item{
    text-align: center;
    width: 100%;
     background: #035797;
    position: relative;
    display: block; 
  }


  .day-text{
     width: 100%;
     text-transform: uppercase;
     font-weight: 600;
     color: white;
  }
  .day-number{
      width: 100%;
      color: white;

  }
  .day-number-selected{
      color: #035797;
  }

  .punkt-selected {
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}
.large-selected{
font-weight: 900;
}
